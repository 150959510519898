
import CIcon from '../../c-icon/c-icon.component.vue'
import { CIconFactory } from '../../c-icon/factory/c-icon.factory'
import { defineComponent, PropType, computed, onMounted, ref, Ref } from 'vue'
import { IFilterTag } from '../interface/i-filter-tag'

export default defineComponent({
  components: {
    CIcon
  },
  props: {
    /**
     * Filter tag props, displayed on the template.
     */
    value: {
      type: Object as PropType<IFilterTag>,
      required: true
    }
  },
  setup(props, { emit }) {
    const tag: Ref<HTMLDivElement> = ref(null) as any
    const closeIcon = CIconFactory.CircleXmarkLeft()
    const tagDataCy = computed(() => {
      const value: IFilterTag = props.value
      return `tag-${value.itemCategoryName}-${value.itemName}`
    })

    /**
     * Emits a signal up to the parent notifying of a close event
     */
    function handleClose(): void {
      emit('close', props.value)
    }

    onMounted(() => {
      emit('tag-updated', tag)
    })

    return {
      // component reference
      tag,

      // data properties
      closeIcon,

      // computed
      tagDataCy,

      // methods
      handleClose
    }
  }
})
