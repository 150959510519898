
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { FilterKey, LocalizationData, LocalizationPath } from '@tokyojob/core'
import {
  ButtonFactory,
  CButton,
  CIcon,
  CIconFactory,
  FilterQueryManager,
  getLocalization,
  IconPlacement,
  IconSize,
  IFilterJPLevelOption
} from '@tokyojob/frontend-components'
import { LocalizationService } from '@tokyojob/frontend-core'
import CFilterMobile from '~/modules/app/components/c-filter/c-filter-mobile.component.vue'
import { CFilterTagList, IFilterTag } from '@tokyojob/frontend-components'
import { IFilterGroup } from '@tokyojob/frontend-components'
import { ISelectedVacancyFilters } from '~/modules/app/components/c-filter/interface/i-selected-vacancy-filters'
import { CFilterPopout } from '@tokyojob/frontend-components'
import { FilterTypes } from '@tokyojob/frontend-components'
import { FilterTagFactory } from '@tokyojob/frontend-components'
import { IFilterMultiOption } from '@tokyojob/frontend-components'
import { IFilterSearch } from '@tokyojob/frontend-components'
import { ISelectedFilterMultiOptions } from '@tokyojob/frontend-components'
import { IVacancyFilterTreeData } from '@tokyojob/frontend-components'
import { SelectedVacancyFiltersDomain } from '../util/selected-vacancy-filters-domain'
import { getProvidedApp, getRouter } from '~/base/utils/provided-getter'

export default defineComponent({
  components: {
    CIcon,
    CFilterTagList,
    CButton,
    CFilterMobile,
    CFilterPopout
  },
  props: {
    /**
     * If menu is open or not
     */
    menuOpen: {
      type: Boolean,
      required: true
    },
    /**
     * Group of filters to be used
     */
    filters: {
      type: Object as PropType<IFilterGroup>,
      required: true
    },
    /**
     * Object containing hierarchical data for the c-filter
     */
    vacancyFilterTreeData: {
      type: Object as PropType<IVacancyFilterTreeData>,
      required: true
    },
    /**
     * CUrrently selected filter tags from parent
     */
    value: {
      type: Object as PropType<ISelectedVacancyFilters>,
      required: true
    }
  },
  setup(props, { emit }) {
    const App = getProvidedApp()
    const router = getRouter()
    const localization: LocalizationData = getLocalization(App)

    const selectedVacancyFiltersDomain = new SelectedVacancyFiltersDomain(
      new FilterQueryManager(),
      new FilterTagFactory(),
      {},
      {}
    )
    const searchJobsTitle = LocalizationService.t<string>(localization, LocalizationPath.FilterSideMenuTitle)
    const cancelBtn = ButtonFactory.button({
      label: LocalizationService.t<string>(localization, LocalizationPath.cancelBtn),
      classes: 'text-big theme-shades-light1-ghost color-shades-white pv-5 ph-10 br-5 g-10 min-w-120 row-around align-center',
      width: 'fit-content',
      icon: CIconFactory.Icon({
        icon: 'arrow-left',
        placement: IconPlacement.Left,
        size: IconSize.medium
      })
    })
    const confirmBtn = ButtonFactory.button({
      label: LocalizationService.t<string>(localization, LocalizationPath.confirmBtn),
      classes: 'text-big theme-shades-light1-color pv-5 ph-10 br-5 g-10 min-w-120 row-around align-center',
      width: 'fit-content',
      icon: CIconFactory.Icon({
        icon: 'circle-check',
        size: IconSize.medium
      })
    })
    const currentSearchFilter = ref<IFilterSearch | undefined>(props.filters.filters[0] as IFilterSearch)
    const selectedFiltersData = ref<ISelectedVacancyFilters>(props.value)
    selectedVacancyFiltersDomain.setTreeData(props.vacancyFilterTreeData)
    const filterTags = ref(selectedVacancyFiltersDomain.createFilterTags(localization, selectedFiltersData.value))
    const currentMultiFilter = ref<IFilterMultiOption | undefined>(undefined)
    const currentJPLevelFilter = ref<IFilterJPLevelOption | undefined>(undefined)
    const popoutValue = ref('')

    const SelectedFiltersData = computed({
      set(value: ISelectedVacancyFilters) {
        selectedFiltersData.value = value
        selectedVacancyFiltersDomain.setTreeData(props.vacancyFilterTreeData)
        filterTags.value = selectedVacancyFiltersDomain.createFilterTags(localization, SelectedFiltersData.value)

        if (currentMultiFilter.value) {
          const temp = { ...currentMultiFilter.value }
          temp.data = { ...SelectedFiltersData.value.multiOption }
          currentMultiFilter.value = temp
        }

        if (currentJPLevelFilter.value) {
          const temp = { ...currentJPLevelFilter.value }
          temp.jpLevel = SelectedFiltersData.value.japaneseLevel
          currentJPLevelFilter.value = temp
        }
      },
      get(): ISelectedVacancyFilters {
        return selectedFiltersData.value
      }
    })

    /**
     * Close the menu without saving filters
     */
    function closeMenu(): void {
      emit('close-menu')
      SelectedFiltersData.value = props.value
      currentMultiFilter.value = undefined
      resetTreePosition()
    }

    /**
     * Close the menu and save filters
     */
    function saveAndCloseMenu(): void {
      emit('save-filters', SelectedFiltersData.value)
      resetTreePosition()
    }

    /**
     * Resets the current filter to the Visa filter.
     */
    function resetTreePosition(): void {
      let newSearchFilter = props.filters.filters[0] as IFilterSearch
      // Recreates object when visa filter has been selected to reset the filter. For other filters, we do not need to do this.
      // Yes, this is disgusting.
      if (currentSearchFilter.value === newSearchFilter) newSearchFilter = { ...newSearchFilter }
      currentSearchFilter.value = newSearchFilter as any
    }

    /**
     * Close the menu and save filters
     */
    function onTagClose(value: IFilterTag): void {
      SelectedFiltersData.value = selectedVacancyFiltersDomain.removeSelection(
        selectedFiltersData.value,
        value.filterKey! as FilterKey
      )

      if (currentMultiFilter.value && value.filterKey === FilterKey.contract) {
        currentMultiFilter.value = { ...currentMultiFilter.value!, data: { contractType: '' } }
      }
    }

    /**
     * Emits the filter value back up to the parent.
     */
    function selectMultiFilters(newFilter: ISelectedFilterMultiOptions): void {
      // TODO: update this to use the SelectedVacancyFiltersDomain.addDataToISelectedVacancyFilters
      SelectedFiltersData.value = { ...SelectedFiltersData.value, multiOption: newFilter }
    }
    /**
     * Emits the jp filter value back up to the parent.
     */
    function selectJPLevelFilter(jpLevel: number): void {
      SelectedFiltersData.value = { ...SelectedFiltersData.value, japaneseLevel: jpLevel }
    }

    /**
     * Updates the currentMultiFiltero object, replacing it with a new one.
     */
    function onContractValueUpdated(newValue: string | undefined): void {
      currentMultiFilter.value = { ...currentMultiFilter.value!, data: { contractType: newValue } }
    }
    /**
     * Updates the currentJPLevelFilter object, replacing it with a new one.
     */
    function onJPLevelValueUpdated(newValue: number): void {
      currentJPLevelFilter.value = { ...currentJPLevelFilter.value!, jpLevel: newValue }
    }
    /**
     * Once a singular item has been selected the futh path string is emitted to the parent
     */
    function selectItem(data: string, category: string): void {
      SelectedFiltersData.value = selectedVacancyFiltersDomain.addDataToISelectedVacancyFilters(
        selectedFiltersData.value,
        category as FilterKey,
        data
      )
    }

    /**
     * Set Open or closed menu class and animation
     */
    function menuState(value: boolean): string {
      return value ? 'menu-open' : 'menu-closed'
    }

    /**
     * Change the selected filter from a button click
     */
    function selectFilter(filter: IFilterSearch | IFilterMultiOption | IFilterJPLevelOption): void {
      popoutValue.value = ''

      if (filter.filterType === FilterTypes.FilterSearch) {
        currentSearchFilter.value = filter as any
        currentMultiFilter.value = undefined
        currentJPLevelFilter.value = undefined
      } else if (filter.filterType === FilterTypes.FilterJPLevelOption) {
        currentSearchFilter.value = undefined
        currentJPLevelFilter.value = filter as any
        currentMultiFilter.value = undefined // reset multi option filter if necessary
      } else {
        const temp = filter as any
        temp.data = { ...SelectedFiltersData.value.multiOption }
        currentMultiFilter.value = temp
        currentSearchFilter.value = undefined
        currentJPLevelFilter.value = undefined // reset JP level filter if necessary
      }
    }

    watch(
      () => props.value,
      (newValue) => {
        selectedFiltersData.value = newValue
        selectedVacancyFiltersDomain.setTreeData(props.vacancyFilterTreeData)
        filterTags.value = selectedVacancyFiltersDomain.createFilterTags(localization, selectedFiltersData.value)
      }
    )

    return {
      cancelBtn,
      confirmBtn,
      popoutValue,
      currentSearchFilter,
      currentMultiFilter,
      currentJPLevelFilter,
      selectedFiltersData,
      filterTags,
      searchJobsTitle,
      App,
      router,
      SelectedFiltersData,
      closeMenu,
      saveAndCloseMenu,
      resetTreePosition,
      onTagClose,
      selectMultiFilters,
      onContractValueUpdated,
      onJPLevelValueUpdated,
      selectItem,
      menuState,
      selectFilter,
      selectJPLevelFilter
    }
  }
})
