import { IFilterGroup } from '../interface/i-filter-group'
import { FilterFactory } from './filter.factory'
import { IVacancyFilterTreeData } from '../interface/i-vacancy-filter-tree-data'
import { LocalizationData } from '../../../../../../../core/src/models/common/localization/localization-data'
import { FilterKey } from '../../../../../../../core/src/models/vacancy/enum/filter-key.enum'

export class FilterGroupFactory {
  /**
   * Create a Search Filter.
   */
  static FilterGroup(props: IFilterGroup): IFilterGroup {
    return {
      classes: props.classes,
      filters: props.filters
    }
  }

  /**
   * Create a specific set of filters for vacancy Search.
   */
  static VacancySearchFilterGroup(localization: LocalizationData, treeData: IVacancyFilterTreeData): IFilterGroup {
    return FilterGroupFactory.FilterGroup({
      classes: 'row-between align-center theme-shades-dark1-color br-15 p-10 g-10',
      filters: [
        FilterFactory.VisaTypeSearchFilter(localization, treeData[FilterKey.visa]),
        FilterFactory.JobCatergorySearchFilter(localization, treeData[FilterKey.category]),
        FilterFactory.LocationSearchFilter(localization, treeData[FilterKey.location]),
        FilterFactory.JapaneseLevelOptionsFilter(localization),
        FilterFactory.OtherSearchFilters(localization)
      ]
    })
  }

  /**
   * Create a specific set of filters for the vacancy map search
   * @param treeData
   * @returns
   */
  static VacancyMapSearchFilterGroup(localization: LocalizationData, treeData: IVacancyFilterTreeData): IFilterGroup {
    return FilterGroupFactory.FilterGroup({
      classes: 'row-between align-center theme-shades-dark1-color br-15 p-10 g-10',
      filters: [
        FilterFactory.VisaTypeSearchFilter(localization, treeData[FilterKey.visa]),
        FilterFactory.JobCatergorySearchFilter(localization, treeData[FilterKey.category]),
        FilterFactory.JapaneseLevelOptionsFilter(localization),
        FilterFactory.OtherSearchFilters(localization)
      ]
    })
  }
}
