
import { ButtonFactory, CButton, IFilterGroup } from '@tokyojob/frontend-components'
import { defineComponent, PropType } from 'vue'
import { IFilter } from '@tokyojob/frontend-components'

export default defineComponent({
  components: { CButton },
  props: {
    filters: {
      type: Object as PropType<IFilterGroup>,
      required: true
    }
  },
  setup(props, { emit }) {
    const mobileFilters = props.filters.filters
    for (const filter of mobileFilters) {
      filter.button = ButtonFactory.button({
        ...filter.button,
        height: '40px'
      })
    }
    const popoutdictionary = {} as Record<string, boolean>
    for (const filter in props.filters.filters) {
      const name = props.filters.filters[filter].name
      popoutdictionary[name] = false
    }

    function selectFilter(filter: IFilter): void {
      emit('select-filter', filter)
    }

    return {
      // data properties
      popoutdictionary,
      mobileFilters,
      currentFilter: 'visa',

      // methods
      selectFilter
    }
  }
})
