
import { defineComponent, PropType, ref, Ref } from 'vue'
import { LocalizationData } from '../../../../../../../core/src/models/common/localization'
import { LocalizationPath } from '../../../../../../../core/src/models/common/localization/constants/localization-path'
import CIcon from '../../c-icon/c-icon.component.vue'
import CLabel from '../../c-label/c-label.component.vue'
import { getLocalization } from '../../../helper/c-translation-helper'
import { LabelFactory } from '../../c-label/factory/label.factory'
import { LocalizationService } from '../../../../../../core/src/modules/language/localization-service'
import { IFilterTag } from '../interface/i-filter-tag'
import CFilterTag from './c-filter-tag.component.vue'

export default defineComponent({
  components: {
    CIcon,
    CFilterTag,
    CLabel
  },
  props: {
    /**
     * Contains properties used by the c-filter-main-button component
     */
    tags: {
      type: Array as PropType<IFilterTag[]>,
      required: true
    },
    App: {
      required: true,
      type: Object as PropType<any>
    }
  },
  setup(props, { emit }) {
    const localization: LocalizationData = getLocalization(props.App)
    const filters: Ref<HTMLDivElement> = ref(null) as any
    const noTagsLabel = LabelFactory.new({
      label: [LocalizationService.t<string>(localization, LocalizationPath.noTags)],
      class: 'theme-shades-medium1-color br-20 ph-10'
    })

    /**
     * Emits a signal up to the parent notifying of a close event
     */
    function handleClose(value: IFilterTag): void {
      emit('tagWasClosed', value)
    }
    /**
     * Scrolls into a tag component.
     */
    function scrollIntoTag(tagComponent: InstanceType<typeof HTMLDivElement>): void {
      const div = filters.value
      if (tagComponent) {
        div.scrollLeft = tagComponent.offsetLeft - div.offsetLeft
      }
    }

    return {
      filters,

      noTagsLabel,

      handleClose,
      scrollIntoTag
    }
  }
})
